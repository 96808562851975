.link {
  text-decoration: none;
  color: #000000;
}

.upload {
  padding: 20px;
}

.login {
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 20px;
}
